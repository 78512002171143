var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: "组织架构",
              isShowdig: false,
              showCheckbox: false,
              expandOnClickNode: false,
              treeExpand: false,
              currentNodeKey: _vm.currentNodeKey,
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              nodeKey: "id",
              defaultCheckedKeys: _vm.defaultCheckedKeys,
              loading: _vm.treeLoading,
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            {
              staticStyle: {
                position: "relative",
                height: "calc(100vh - 94px)",
              },
            },
            [
              _c(
                "head-layout",
                {
                  staticClass: "head-section",
                  attrs: {
                    "head-btn-options": _vm.headBtnOptions,
                    "head-title": "政府部门生态环保处罚情况统计",
                  },
                  on: {
                    "head-edit": _vm.headEdit,
                    "head-save": _vm.headSave,
                    "head-submit": _vm.headSubmit,
                    "head-summary": _vm.headSummary,
                    "head-reportView": _vm.headReportView,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "left" },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          type: "month",
                          format: "yyyy-MM",
                          "value-format": "yyyy-MM",
                          size: "mini",
                          disabled: _vm.type === "workflow",
                        },
                        on: { change: _vm.chooseDate },
                        model: {
                          value: _vm.query.statDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.query, "statDate", $$v)
                          },
                          expression: "query.statDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticStyle: { padding: "0 12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.dataForm,
                        rules: _vm.rules,
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "组织名称：",
                                    prop: "companyName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.dataForm.companyName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "companyName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.companyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "填报日期",
                                    prop: "updateTime",
                                  },
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("span", [_vm._v("填报日期:")]),
                                  ]),
                                  _c("el-date-picker", {
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      disabled: "true",
                                      placeholder: "保存后自动生成",
                                    },
                                    model: {
                                      value: _vm.dataForm.updateTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "updateTime",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.updateTime",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "数据状态：",
                                    prop: "dataState",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.dataForm.dataState,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "dataState",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.dataState",
                                      },
                                    },
                                    _vm._l(_vm.dataStateDic, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "月检查次数：",
                                    prop: "monthCheckCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.monthCheckCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "monthCheckCnt",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.monthCheckCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.monthCheckCntS < 0 ||
                                          _vm.dataForm.monthCheckCntS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.monthCheckCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "月问题总数：",
                                    prop: "monthProblemCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.monthProblemCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "monthProblemCnt",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.monthProblemCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.monthProblemCntS < 0 ||
                                          _vm.dataForm.monthProblemCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.monthProblemCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "月整改数：",
                                    prop: "monthRectifiedCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.monthRectifiedCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "monthRectifiedCnt",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.monthRectifiedCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.monthRectifiedCntS < 0 ||
                                          _vm.dataForm.monthRectifiedCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.monthRectifiedCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "月处罚起数：",
                                    prop: "monthAccumulatePunishCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value:
                                        _vm.dataForm.monthAccumulatePunishCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "monthAccumulatePunishCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.monthAccumulatePunishCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm
                                            .monthAccumulatePunishCntS < 0 ||
                                          _vm.dataForm
                                            .monthAccumulatePunishCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .monthAccumulatePunishCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "月处罚罚款（万元）：",
                                    prop: "monthAccumulatePunishAmount",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "10",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value:
                                        _vm.dataForm
                                          .monthAccumulatePunishAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "monthAccumulatePunishAmount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.monthAccumulatePunishAmount",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm
                                            .monthAccumulatePunishAmountS < 0 ||
                                          _vm.dataForm
                                            .monthAccumulatePunishAmountS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .monthAccumulatePunishAmountS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "年累计检查数：",
                                    prop: "yearAccumulateCheckCnt",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.yearAccumulateCheckCnt) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.yearAccumulateCheckCntS <
                                            0 ||
                                          _vm.dataForm
                                            .yearAccumulateCheckCntS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .yearAccumulateCheckCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年累计问题数：" } },
                                [
                                  _vm._v(
                                    "\n\n                " +
                                      _vm._s(_vm.yearAccumulateProblemCnt) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm
                                            .yearAccumulateProblemCntS < 0 ||
                                          _vm.dataForm
                                            .yearAccumulateProblemCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .yearAccumulateProblemCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年整改数：" } },
                                [
                                  _vm._v(
                                    "\n\n                " +
                                      _vm._s(_vm.yearRectifiedCnt) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.yearRectifiedCntS < 0 ||
                                          _vm.dataForm.yearRectifiedCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.yearRectifiedCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "月整改率：" } },
                                [
                                  _vm._v(
                                    "\n\n                " +
                                      _vm._s(_vm.monthRectifiedRate) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.monthRectifiedRateS ==
                                            null ||
                                          _vm.dataForm.monthRectifiedRateS ==
                                            null ||
                                          false
                                            ? "上次填报数据：100%"
                                            : "上次填报数据：" +
                                              _vm.dataForm.monthRectifiedRateS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年整改率：" } },
                                [
                                  _vm._v(
                                    "\n\n                " +
                                      _vm._s(_vm.yearRectifiedRate) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.yearRectifiedRateS ==
                                            null ||
                                          _vm.dataForm.yearRectifiedRateS ==
                                            null ||
                                          false
                                            ? "上次填报数据：100%"
                                            : "上次填报数据：" +
                                              _vm.dataForm.yearRectifiedRateS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年处罚起数：" } },
                                [
                                  _vm._v(
                                    "\n\n                " +
                                      _vm._s(_vm.yearAccumulatePunishCnt) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm
                                            .yearAccumulatePunishCntS < 0 ||
                                          _vm.dataForm
                                            .yearAccumulatePunishCntS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .yearAccumulatePunishCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年处罚罚款（万元）：" } },
                                [
                                  _vm._v(
                                    "\n\n                " +
                                      _vm._s(_vm.yearAccumulatePunishAmount) +
                                      "\n                "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm
                                            .yearAccumulatePunishAmountS < 0 ||
                                          _vm.dataForm
                                            .yearAccumulatePunishAmountS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .yearAccumulatePunishAmountS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("head-layout", {
                attrs: {
                  "head-title": "下级组织项目人员信息",
                  "head-btn-options": _vm.subordinateBtn,
                },
                on: {
                  "head-reject": _vm.reject,
                  "head-urge-processing": _vm.headUrging,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                staticClass: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                class:
                  _vm.type != "workflow"
                    ? "governmentDepartmentSafetyProduction"
                    : "",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "cell-style": _vm.cellStyle,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowView,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return undefined
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.governmenEditFilling
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              attrs: {
                title: "政府部门生态环保处罚情况统计编辑",
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.governmenEditFilling,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.governmenEditFilling = $event
                },
                close: _vm.governmenEditDialog,
              },
            },
            [
              _c("governmenEditDialog", {
                ref: "governmenEditDialog",
                attrs: { rowQuery: _vm.rowQuery },
                on: { governmenEditDialog: _vm.governmenEditDialog },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }